export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Polaris-Boats',
  hostName: 'https://polarisboatsus-analytics-prod.azurewebsites.net',
  environmentName: 'production',
  appName: 'Polaris Boats Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '80e95543-0a8f-4511-aa39-6ef2be461900'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#1b365c', '#47ddfc', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://polarisboatsus-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://polarisboatsus-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://polarisboats-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/polaris-boats/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  harmonyUrl: '',
  v5Reports: []
};
